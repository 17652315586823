var app;
(function (app) {
    var contacts;
    (function (contacts) {
        'use strict';
        var ContactsController = /** @class */ (function () {
            /* @ngInject */
            ContactsController.$inject = ["$scope", "$element", "$document", "$timeout", "$q", "dataservice", "$state", "$location", "$rootScope", "rxData"];
            function ContactsController($scope, $element, $document, $timeout, $q, dataservice, $state, $location, $rootScope, rxData) {
                var _this = this;
                this.$scope = $scope;
                this.$element = $element;
                this.$document = $document;
                this.$timeout = $timeout;
                this.$q = $q;
                this.dataservice = dataservice;
                this.$state = $state;
                this.$location = $location;
                this.$rootScope = $rootScope;
                this.rxData = rxData;
                this.$onInit = function () {
                    rxData.rootScopeOnready().then(function (data) {
                        _this.pageOBJ = data;
                        console.log(' this.pageOBJ timeline', _this.pageOBJ);
                        $timeout(function () {
                            $scope.$apply();
                        });
                    }, function (err) {
                        _this.pageOBJ = false;
                        console.log('error on home', err);
                    });
                };
            }
            ;
            return ContactsController;
        }());
        contacts.ContactsController = ContactsController;
        var ContactsComponent = /** @class */ (function () {
            function ContactsComponent() {
                this.restrict = 'E';
                this.controllerAs = 'vm';
                this.templateUrl = 'dist/js/app.contacts.html';
                this.controller = ContactsController;
            }
            return ContactsComponent;
        }());
        angular
            .module('app.contacts', []);
        angular
            .module('app.contacts').component('contacts', new ContactsComponent());
    })(contacts = app.contacts || (app.contacts = {}));
})(app || (app = {}));
